import React, { } from "react";
// import WebFont from 'webfontloader'; // NOTE: webfonts from google is no compliant to GDPR

import Home from "./pages/Home.js";
import Rates from "./pages/Rates.js";
import Events from "./pages/Events.js";
import Team from "./pages/Team.js";
import Faqs from "./pages/Faq.js";

import './style.css';
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./layout/Layaout.js";
import EventDetails from "./pages/EventDetails.js";
import TeamDetails from "./pages/TeamDetails.js";
import Page404 from "./pages/NotFound.js";
import RatesDetails from "./pages/RatesDetails.js";
import AboutJSW from "./pages/AboutFSW.js";

function App() {



  return (
    <Layout>
      <Routes>  {/* NOTE: In react-router-dom v6, "Switch" is replaced by routes "Routes". */}
          
          <Route path='/' exact element={< Navigate to = '/home'/>} />

          <Route path='/home' element={ <Home/> }/>
          <Route path='/events' element={ <Events/> }/>
          <Route path='/team' element={ <Team/> }/>
          <Route path='/rates' element={< Rates/> }/>
          <Route path='/faq' element={ <Faqs/> }/>
          {/* {pageStr === 'faq'  && <Faqs />}   NOTE: the old way */}

          <Route path='/event-details/:eventId' element={ <EventDetails/> } />
          <Route path='/team-details/:memberId' element={ <TeamDetails/> } />
          <Route path='/rates-details' element={ <RatesDetails/> } />
          <Route path='/about-fsw' element={ <AboutJSW/> } />
          <Route path='*' element={ <Page404/> } />
      </Routes>
    </Layout>
  );
}

export default App;

