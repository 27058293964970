import React from "react";
import { PageWrapper } from "../components/Shared/Shared";
import DATA from "../DATA";

const AboutJSW = () => {
    const legalEntity = DATA.legalEntity
    const stakeholders = DATA.stakeholders
    const disclaimer = DATA.disclaimer

    // const stringToHTML = function (str) {
    //     var parser = new DOMParser();
    //     var doc = parser.parseFromString(str, 'text/html');
    //     return doc.body;
    // };

    return (
        <PageWrapper
        title={ 'Imprint' }  
        description= { "" }
        >
            <p>
                <strong>{ legalEntity.name }</strong><br/>
                {legalEntity.address}<br/>
                { `${legalEntity.zip} ${legalEntity.city}` }<br/>
                {legalEntity.phone}<br/>
                <a href={`mailto:${legalEntity.email}`} target="_blank" rel="noopener noreferrer"> {legalEntity.email}</a>
                <br/>
                <a href={legalEntity.site}> {legalEntity.site}</a>
            </p>
            {/*  */}

            {
                stakeholders.map((item, index) => (
                    <p key={index}>
                        <strong>{item.role}:</strong><br/>
                        {item.name}
                    </p>
                ))
            }
            <hr></hr>
            <br/>
            {
                disclaimer.map((item, index) => (
                    <p key={index}>
                        <strong>{item.title}:</strong><br/>
                        {item.text}
                    </p>
                    
                ))
            }

      </PageWrapper>




    )
}


export default AboutJSW;