import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import { PageWrapper } from "../components/Shared/Shared";
import BoatGallery from "../components/Gallery/BoatGallery";
import { ref } from "firebase/storage";
import { getImageFolder, storage,  } from "../firebase/firebase";

import DATA from "../DATA";


const RatesDetails = () => {
    const { ratesData } = DATA

    const [imageUrls, setImageUrls] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    
    const location = useLocation();  
    const queryParams = new URLSearchParams(location.search);  // ?medal=asc
    const getMedal = queryParams.get('medal');  // asc
    
    const imagesListRef = ref(storage, `rates/${getMedal}`);
    
    const fetchData = useCallback(() => {
        getImageFolder(imagesListRef, setImageUrls, setIsLoading, setError);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchData()
    }, [fetchData,])


    // const params = useParams();


    // const member = teamDataFull.find((member) => member.id === params.memberId)
    //
    // if(!member) {
    //     return <p>Member not found</p>
    // } 

    const rate = ratesData.find((rate) => rate.id.toLowerCase() === getMedal)

    return (
    <PageWrapper
        title={ rate.title }  
        description= { rate.description }
    >
        <h4>
        Typical boats in this category: 
            { 
                rate.typicalBoats.length > 0 && 
                    rate.typicalBoats.map((item, index) => (
                        <><a key={index} href={ item.googleLink }>{ item.boat }</a>,&nbsp;</>
                )) 
            }
            {
                rate.typicalBoats.length === 0 && 
                    <span style={{fontWeight: 'normal'}}>&nbsp;
                    {'It will depend on the availability of the other categories.'}</span>
            }
        </h4>
        <br/>
        {
            rate.paragraphs.map((item, index) => (
                <p key={ index }>{ item }</p>
            ))
        }
        {/* <p>Typical boats in this category: { rate. }Lagoon 40, Bavaria 50.</p>
        <p>Find out some more fotos 
            <a> here</a>.
        </p> */}
        <h4 style={{textAlign: 'center'}}>
            Credit: Photos below are from: &nbsp;
            <a href="https://www.yacht-rent.com/photo-gallery">https://www.yacht-rent.com/photo-gallery</a>
        </h4>
        <BoatGallery imageUrls={imageUrls} loading={isLoading} error={error} type={ 'hero' }/>
        {/* <hr/> */}
        {/* <DecoyPricing data={ ratesData } /> */}
    </PageWrapper>
    )
}


export default RatesDetails;


/*
const DOMTest = (props) => {
    const doc = new DOMParser().parseFromString(props, "application/xml");
    const htmlSections = doc.childNodes[0].childNodes;
    var sections = Object.keys(htmlSections).map((key, i) => {
    let el = htmlSections[key];
    let contents = [<p>{el.innerHTML}</p>];
    if (el.hasAttribute("but")) contents.push(<button>Comment</button>);
    return <div key={i}>{contents}</div>;
    });

    return <div>{sections}</div>;
    
}
*/