import React from "react";
import { DivDiagonalLine } from "../components/Shared/Shared";

import classes from './Footer.module.css'
import DATA from "../DATA.js";
import { SvgIcon } from "../components/Shared/IconList";


export default function Footer() {
  const {socialLinks, otherLinks } = DATA;

  return (
    <>
    <footer className={ classes.footer }>
        <DivDiagonalLine />
        <div className= { classes.wrapper }>

            <SocialLinksFooter sl={ socialLinks } className={ classes.social } />

            <FooterOtherResourcesList 
                lstTitles= { Object.keys(otherLinks)[0] }
                lstItems= {  otherLinks["About FSW"] } />

                {/* <FooterOtherResourcesList 
                    lstTitles= { Object.keys(otherLinks)[1] }
                    lstItems= {  otherLinks["Other Links"] } 
                    /> */}
        </div>
        <div className={ classes.bottom }>
            {/* <hr className=""/> */}
            <div className={ classes.copyright }>
              Copyright © {new Date().getFullYear()}{" "}Forró Sailing Week GbR.{" "}
            </div>
        </div>
    </footer>
    </>
  );
}


export function FooterOtherResourcesList(props) {
  return (
      <div className={ classes.list }>
          <h4 className={ classes.title }>
              { props.lstTitles}
          </h4>
          <ul>
          { 
              props.lstItems.map( (item, index) => (
                  <li key={ index }>
                      <a href={ item.link }> 
                          {item.name}
                      </a>
                  </li>
              )) 
          }
          </ul>
      </div>
  )
}

export function SocialLinksFooter(props) {
    const onClickHandler = (str) => {
        //console.log(str)
        //e.preventDefault();
        window.open(str);
    }
  return (
      <div className={ classes.social}>
        <ContactFooter/>
          {/* <h3> Let's keep in touch! </h3> */}
          {/* <p> Find us on any of these platforms. </p> */}
          { 
              props.sl.map( (item, index) => (
                  <button key= { index }
                      className={ `${ classes.socialIcon } ${""}`}
                      style={ { color: item.color } }
                      type="button"
                      onClick={ () => onClickHandler(item.link) }
                  >
                    <SvgIcon icon={ item.stl } fill={ item.color } />  
                  </button>
              )) 
          }
      </div>
  );
}

export function ContactFooter() {
    const { legalEntity } = DATA;
    return (
        <>
            <div className={ classes.contact_inner_wrapper }>
                <div className={ classes.item }><h4>Forró Sailing Week (FSW)</h4></div>

                <div className={ classes.item }>
                    <span>PHONE</span> <span style={{ color: 'black' }}>{ legalEntity.phone }</span>
                </div>

                <div className={ classes.item }>
                    <span>EMAIL</span>
                    <a href={`mailto:${legalEntity.email}`}><span></span></a>{ legalEntity.email }
                </div>
            </div>
        </>
    );
}
