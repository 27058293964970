import React from "react";
import { PageWrapper } from "../components/Shared/Shared";

const Page404 = () => {
  return (
    <PageWrapper title={ 'Page Not found' } description={"Try it again..."}>
      <h1>{"I am sorry"}</h1>
    </PageWrapper>
  );
};

export default Page404;
